import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import blue from './assets/blue_flower.png';
import purple from './assets/purple_flower.png';
import pink from './assets/pink_flower.png';


function App() {
    var i = 0;
    var sub = "a capable human"; /* The text */
    var speed = 75; /* The speed/duration of the effect in milliseconds */

    useEffect(() => {
        typeWriter();
    })

    const typeWriter = () => {
        if (i < sub.length) {
            document.getElementById("typed").innerHTML += sub.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        }
    }

    return (
        <div className="App">
            <Nav
                className="nav"
                activeKey="/home"
                onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
                <Nav.Item>
                    <Nav.Link href="https://www.patientpop.com/">PatientPop</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.linkedin.com/in/mpedicone/">LinkedIn</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        You wish you knew
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <div className="main">
                <h1>Michelle Pedicone</h1>
                <p id="typed"></p>
            </div>

            <div className="things">
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={purple} />
                    <Card.Body>
                        <Card.Title>Step One</Card.Title>
                        <Card.Text>
                            How I made this `app` so quickly:
                        </Card.Text>
                        <DropdownButton id="dropdown-basic-button" title="React&nbsp;&nbsp;">
                            <DropdownItem id="first-item" href="https://reactjs.org/">React</DropdownItem>
                            <DropdownItem href="https://www.jetbrains.com/webstorm/">+ WebStorm IDE</DropdownItem>
                        </DropdownButton>
                    </Card.Body>
                </Card>
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={blue} />
                    <Card.Body>
                        <Card.Title>Step Two</Card.Title>
                        <Card.Text>
                            How I made it `pretty` easily:
                        </Card.Text>
                        <DropdownButton id="dropdown-basic-button" title="Bootstrap&nbsp;&nbsp;">
                            <Dropdown.Item id="first-item" href="https://react-bootstrap.github.io/">Bootstrap</Dropdown.Item>
                            <DropdownItem href="https://www.canva.com/">+ Canva</DropdownItem>
                            <DropdownItem href="https://webgradients.com/">+ WebGradients</DropdownItem>
                        </DropdownButton>
                    </Card.Body>
                </Card>
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={pink} />
                    <Card.Body>
                        <Card.Title>Step Three</Card.Title>
                        <Card.Text>
                            How I served this to you:
                        </Card.Text>
                        <DropdownButton id="dropdown-basic-button" title="AWS&nbsp;&nbsp;">
                                <Dropdown.Item href="https://aws.amazon.com/s3/?nc2=type_a">S3</Dropdown.Item>
                                <Dropdown.Item href="https://aws.amazon.com/route53/">Route53</Dropdown.Item>
                                <Dropdown.Item href="https://aws.amazon.com/certificate-manager/?nc2=type_a">Certificate Manager</Dropdown.Item>
                                <Dropdown.Item href="https://aws.amazon.com/cloudfront/?nc2=type_a">CloudFront</Dropdown.Item>
                                <Dropdown.Item href="https://aws.amazon.com/cli/?nc2=type_a">AWS CLI</Dropdown.Item>
                        </DropdownButton>
                    </Card.Body>
                </Card>
            </div>
        </div>
);

}

export default App;
